
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { RouteNames } from '@/enums/routes/RouteNames';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { LocalStorageService } from '@/services/LocalStorageService';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRightsEnum } from '@/enums/global/UserRights';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import HelpPage from '@/components/global/HelpPage.vue';

@Component({
    name: 'HomeTopBar',
    components: { HelpPage },
})
export default class HomeTopBar extends Vue {
    @Prop({ required: true }) private isDeleteMode!: boolean;
    @Prop({ required: true }) private showPrintButton!: boolean;
    @Prop({ default: false }) private showClientProjectsOptions!: boolean;
    @Prop({ default: null }) private selectedClientId!: string | null;
    @Prop({ required: true }) private title!: string | null;

    private areFiltersVisible = false;

    public get canSeeAdminTab() {
        return this.userRights.includes(UserRightsEnum.VIEW_LEAD_ASSIGN);
    }

    public get canDeleteProjects() {
        return this.userRights.includes(UserRightsEnum.DELETE_PROJECTS);
    }

    public get canSeeB2BControlPanel() {
        if (this.currentUserGroup == null || this.currentUserGroup.name !== 'Roltek') {
            return false;
        }
        return this.userRights.includes(UserRightsEnum.B2B_CONTROL_PANEL);
    }

    public canSeeInquiries() {
        return this.userRights.includes(UserRightsEnum.SEE_INQUIRIES);
    }

    protected get projectTypeOptions(): RadioButtonGroupOptions[] {
        const options: RadioButtonGroupOptions[] = [
            {
                value: ProjectTypes.All as string,
                label: this.$t('All Projects') as string,
            },
            {
                value: ProjectTypes.Lead as string,
                label: this.$t('Inquiries') as string,
            },
            {
                value: ProjectTypes.Offer as string,
                label: this.$t('Offers') as string,
            },
            {
                value: ProjectTypes.Order as string,
                label: this.$t('Orders') as string,
            },
        ];

        if (this.canSeeAdminTab && !this.showClientProjectsOptions) {
            options.push({
                value: ProjectTypes.Admin as string,
                label: this.$t('Control panel') as string,
            });
        }

        if (this.canSeeB2BControlPanel) {
            options.push({
                value: ProjectTypes.Request as string,
                label: this.$t('B2B control panel') as string,
            });
        }
        return options;
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get defaultValue() {
        if (this.$route.name === RouteNames.editClient) {
            return this.projectTypeOptions[1].value;
        }
        const lastSelectedTab = this.projectTypeOptions.find(
            (projectTypeOption: RadioButtonGroupOptions) =>
                projectTypeOption.value === LocalStorageService.get('lastSelectedTab'),
        );
        if (lastSelectedTab == null) {
            return this.projectTypeOptions[1].value;
        }
        return lastSelectedTab.value;
    }

    private showNewProjectPopup() {
        EventBus.$emit(EventBusEvents.toggleNewProjectModal, true);
    }

    public get canSeeInfoButtonsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_INFO_BUTTONS);
    }

    private toggleFilters() {
        this.areFiltersVisible = !this.areFiltersVisible;
    }

    private onToggleDeleteMode() {
        EventBus.$emit(EventBusEvents.updateIsDeleteMode, { isDeleteMode: !this.isDeleteMode });
    }

    private onUpdateEntityType(entityType: string) {
        EventBus.$emit(EventBusEvents.updateIsDeleteMode, { isDeleteMode: false });
        this.$emit('updateEntityType', entityType);
    }

    private created() {
        EventBus.$on(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$on(EventBusEvents.updateClientProjectTab, this.onUpdateEntityType);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$off(EventBusEvents.updateClientProjectTab, this.onUpdateEntityType);
    }
}
